export const setStorefrontId = (storefrontId: string): Rfq.SetStorefrontId => ({
	type: 'rfq/SET_STOREFRONT_ID',
	payload: storefrontId,
});

export const openRfqModal = (): Rfq.OpenRfqModal => ({
	type: 'rfq/OPEN_MODAL',
});

export const closeRfqModal = (): Rfq.CloseRfqModal => ({
	type: 'rfq/CLOSE_MODAL',
});

export const setButtonType = (type: string): Rfq.SetButtonType => ({
	type: 'rfq/SET_BUTTON_TYPE',
	payload: type,
});

export const setRfqFormData = (data: Rfq.FormData): Rfq.SetRfqFormData => ({
	type: 'rfq/SET_FORM_DATA',
	payload: data,
});

export const clearRfqFormData = (): Rfq.ClearRfqFormData => ({
	type: 'rfq/CLEAR_FORM_DATA',
});

export const updateLastVendorNameSent = (
	name: string,
): Rfq.UpdateLastVendorNameSent => ({
	type: 'rfq/UPDATE_LAST_VENDOR_NAME_SENT',
	payload: name,
});

export const setRegistrationStatus = (
	isUserRegistered: boolean,
): Rfq.SetRegistrationStatus => ({
	type: 'rfq/SET_REGISTRATION_STATUS',
	payload: isUserRegistered,
});

export const setAccountCreationStatus = (
	creationStatus: Rfq.StatusTypes,
): Rfq.SetAccountCreationStatus => ({
	type: 'rfq/SET_ACCOUNT_CREATION_STATUS',
	payload: creationStatus,
});

export const setUserJourneyStatus = (
	userJourneyStatus: 'init' | 'exit-early' | 'complete',
): Rfq.SetUserJourneyStatus => ({
	type: 'rfq/SET_USER_JOURNEY_STATUS',
	payload: userJourneyStatus,
});

export const setChangePasswordStatus = (
	changeSuccessful: Rfq.StatusTypes,
	message: string,
): Rfq.SetChangePasswordStatus => ({
	type: 'rfq/SET_CHANGE_PASSWORD_STATUS',
	payload: { changeSuccessful, message },
});

export const updateFieldChange = (
	field: Rfq.InlineFieldShape,
	name: Rfq.InlineFieldKeys,
): Rfq.UpdateFieldChange => ({
	type: 'rfq/UPDATE_FIELD_CHANGE',
	payload: { field, name },
});

export const updateAllFieldsChange = (
	values: Partial<Rfq.Inline>,
): Rfq.UpdateAllFieldsChange => ({
	type: 'rfq/UPDATE_ALL_FIELDS_CHANGE',
	payload: { values },
});

export const updateIsDateFlexible = (
	status: boolean,
): Rfq.UpdateIsDateFlexible => ({
	type: 'rfq/UPDATE_IS_DATE_FLEXIBLE',
	payload: status,
});

export const updateIsDatePickerOpen = (
	status: boolean,
): Rfq.UpdateIsDatePickerOpen => ({
	type: 'rfq/UPDATE_IS_DATE_PICKER_OPEN',
	payload: status,
});

export const updateShowErrors = (status: boolean): Rfq.UpdateShowErrors => ({
	type: 'rfq/UPDATE_SHOW_ERRORS',
	payload: status,
});

export const updateAreErrorsInForm = (
	status: boolean,
): Rfq.UpdateAreErrorsInForm => ({
	type: 'rfq/UPDATE_ARE_ERRORS_IN_FORM',
	payload: status,
});

export const updateIsInlineFormOpen = (
	status: boolean,
): Rfq.UpdateIsInlineFormOpen => ({
	type: 'rfq/UPDATE_IS_INLINE_FORM_OPEN',
	payload: status,
});
